.cc-bullet-list {
}

.cc-bullet-list-item {
  background-image: url('/src/components/tickMark.svg');
  background-size: 18px 18px;
  background-repeat: no-repeat;
  padding-left: 26px;
}

.cc-bullet-list-item:not(:first-child) {
  margin-top: 16px;
}

.cc-loanAmount-input:before {
  content: '$';
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.carsAndStars {
  background-color: #fff;
  background-image: url('/src/components/carsAndStars.svg');
  background-position: right bottom;
  background-repeat: no-repeat;
}

.businessBanner {
  background-color: #fff;
  background-image: url('/src/components/business-banner.svg');
  background-position: right bottom;
  background-repeat: no-repeat;
}

.banner-bg{
  /* width: 200px; */
  height: 156px;
  background-position: center;
}
